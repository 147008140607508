var idleTime = 0;
var duration = 30;
var idleInterval = setInterval(timerIncrement, 1000);
var loop = false;
var timeout;

$(document).ready(function(){

	$('.next-btn').on('click', function(){
		var max = $('.videoGrid').find('input:last').attr('number');
		var next_number = $('.next').attr('number');

		var next_previous = parseInt(next_number) - 1;
		var next_next = parseInt(next_number) + 1;

		if (next_next > max) {
			next_next = 0;
		}

		// Fill "current"
		$('.current-thumb').attr('src', $('.next').attr('thumb'));


		// Change classes
		$('.previous').removeClass();
		$('.next').removeClass('next').addClass('active');
		$('input[number=' + next_next +']').removeClass().addClass('next');
		$('input[number=' + next_previous +']').removeClass().addClass('previous');

		// Fill "next"
		$('.next-thumb').attr('src', $('.next').attr('thumb'));

		// Slide numbers
		var slide_nr = parseInt($('.active').attr('number')) + 1;
		$('.slide-nr').empty().html(slide_nr);

		$.ajax({
            url: '/play/' + $('.active').attr('content'),
            type: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            async: false,
            success: function (data) {
              if (data > 0) {
                duration = data;
              } else {
                duration = 30;
              }
            }
        });
	});

	$('.prev-btn').on('click', function(){
		var max = $('.videoGrid').find('input:last').attr('number');
		var prev_number = $('.previous').attr('number');
		var next_previous = parseInt(prev_number) - 1;
		var next_next = parseInt(prev_number) + 1;

		if (next_next > parseInt(max)) {
			next_next = 0;
		}

		if (next_previous < 0) {
			next_previous = max;
		}

		// Fill "current"
		$('.current-thumb').attr('src', $('.previous').attr('thumb'));

		// Change classes
		$('.next').removeClass();
		$('.active').removeClass();
		$('.previous').removeClass('previous').addClass('active');
		$('input[number=' + next_next +']').removeClass().addClass('next');
		$('input[number=' + next_previous +']').removeClass().addClass('previous');

		// Fill "next"
		$('.next-thumb').attr('src', $('.next').attr('thumb'));

		// Slide numbers
		var slide_nr = parseInt($('.active').attr('number')) + 1;
		$('.slide-nr').empty().html(slide_nr);

		$.ajax({
            url: '/play/' + $('.active').attr('content'),
            type: 'GET',
            contentType: "application/json; charset=utf-8",
            dataType:"json",
            async: false,
            success: function (data) {
              if (data > 0) {
                duration = data;
              } else {
                duration = 30;
              }
            }
        });
	});
	
	var prev = "";
    $(document).on('click', '#wrapperContent .videoGrid li', function(e){
        e.preventDefault();

        if ($('.active')[0] && !$(this).hasClass('active')) {
			// $.post("/SendUDP", { key: "SendUDP", value: 'Stop' } );
			$.post("/SendUDP", { key: "SendUDP", value: $(this).attr('nr')} );
			$('.active').removeClass('active');
			clearTimeout(timeout);
        } else if ($('.active')[0] && $(this).hasClass('active')) {
			$.post("/SendUDP", { key: "SendUDP", value: 'Stop' } );
			$('.active').removeClass('active');
			clearTimeout(timeout);
			return;
		}
        // $(this).addClass('active');
        $('.active').removeClass('active');

        if($(this).hasClass('active')){
            $(this).removeClass('active');
        }else{
            $(this).addClass('active');
        }

        var media = $(this).attr('media');

        if (media != 0) {
            loop = false;
        }

        // $(this).attr('onclick', 'sendUDPString("Stop")');

        var duration = parseInt($(this).attr('duration')) * 1000;
        timeout = setTimeout(function(){ 
			// $('.active').attr('onclick', 'sendUDPString("' + $('.active').attr('nr') + '")');
			$('.active').removeClass('active'); 
		}, duration);


        // $.ajax({
        //     url: '/play/' + media,
        //     type: 'GET',
        //     contentType: "application/json; charset=utf-8",
        //     dataType:"json",
        //     async: false,
        //     success: function (data) {
        //       if (data > 0) {
        //         duration = data;
        //       } else {
        //         duration = 30;
        //       }
        //     }
        // });
    });

    $(this).mousemove(function (e) {
        idleTime = 0;
    });

    $(this).keypress(function (e) {
        idleTime = 0;
    });

    heightLogin();
});

function addInputField(clearValue) {
    var field = document.getElementById('inputFields');
    var input =  document.getElementsByClassName('inputField');
    var count = input.length;
    var lastElement = input[count-1];

    var cln = lastElement.cloneNode(true);
    var removing = document.getElementsByClassName('toRemoveElement');

    while(removing[0]) {
        removing[0].parentNode.removeChild(removing[0]);
    }​
    field.appendChild(cln);

    var newInput = document.querySelectorAll(".inputField input")[count];
    if (clearValue) {
        newInput.value = "";
    }
    var inputCount = count + 1;
    newInput.name = "email" + inputCount;
    document.getElementById('countInputs').value = inputCount;
    $('[name="input_count"]').val(parseInt($('.input-count').val())+1);
}

function timerIncrement() {
    idleTime = idleTime + 1;
    if (idleTime > duration && !loop) {
        idleTime = 0;
        $('.first').click();
        loop = true;
    }
}

//HEIGHT RETREAT HEADER
function heightLogin(){
    $screenHeight = $(window).height();

    $("#wrapperLogin").css({'height': $screenHeight});
}

equalheight = function(container){

var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {

   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;

   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}

$(window).load(function() {
  equalheight('#wrapperContent .videoGrid li');
});


$(window).resize(function(){
  equalheight('#wrapperContent .videoGrid li');
  heightLogin();
});
